import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { divider } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { ButtonDirection } from "./wizardTypes"

export function WizardButtonRow({
  onBack,
  onNext,
  currentStep,
  backButtonProps,
  nextButtonProps,
  backButtonLabel,
  nextButtonLabel,
  hideNextButton,
  hideBackButton,
  border = false,
  direction = ButtonDirection.ROW,
}: {
  onBack?: () => void
  onNext?: () => void
  currentStep: number
  backButtonProps?: TMButtonProps
  nextButtonProps?: TMButtonProps
  backButtonLabel?: string
  nextButtonLabel?: string
  hideNextButton?: boolean
  hideBackButton?: boolean
  border?: boolean
  direction?: ButtonDirection
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box direction={direction} $border={border}>
      <BackButtonBox>
        {!hideBackButton && (
          <TextButton onClick={onBack} {...backButtonProps}>
            {backButtonLabel || t(langKeys.back)}
          </TextButton>
        )}
      </BackButtonBox>
      <NextButtonBox direction={direction} $hideBackButton={hideBackButton}>
        {!hideNextButton && (
          <MButton
            //This is needed to force a rerender, otherwise if you have a step
            //with a form on the next step, it will fire a form event essentially
            //skipping a step
            key={currentStep}
            type={!!nextButtonProps?.form ? "submit" : "button"}
            onClick={!nextButtonProps?.form ? onNext : undefined}
            fullWidth
            {...nextButtonProps}
          >
            {nextButtonLabel || t(langKeys.next)}
          </MButton>
        )}
      </NextButtonBox>
    </Box>
  )
}

const Box = styled.div<{
  direction: ButtonDirection
  $border: boolean
}>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === ButtonDirection.COLUMN ? "column-reverse" : "row"};
  justify-content: space-between;
  align-items: center;
  ${({ $border: border }) =>
    border && `padding-top: ${spacing.XL2}; border-top: 1px solid ${divider};`}
`

const BackButtonBox = styled.div`
  justify-content: start;
`

const NextButtonBox = styled.div<{
  direction: ButtonDirection
  $hideBackButton?: boolean
}>`
  justify-content: end;

  ${({ direction, $hideBackButton: hideBackButton }) =>
    direction === ButtonDirection.COLUMN &&
    `
  width: 100%;

  ${!hideBackButton && `margin-bottom: ${spacing.L};`}


  `}
`
